import React from 'react'
import { SpacerV } from '../composing/Spacing'
import { TextCaption, TextDisplayMedium } from '../composing/CSText'
import { Routes } from '../scopes/nav/constants'
import { Colors } from '../theme/constants'
import { CardItem } from './CardCarousel'
import { formatSymbol } from '@commonstock/common/src/utils/format'

type AssetListItemProps = {
  badge: React.ReactChild
  name: string
  symbol: string
  type: string
}

function AssetListItem({ badge, name, symbol, type }: AssetListItemProps) {
  return (
    <CardItem href={Routes.asset(symbol, type)}>
      <TextDisplayMedium>{formatSymbol(symbol, type)}</TextDisplayMedium>
      <SpacerV rem={0.375} />
      <TextCaption block ellipsis color={Colors.TextSecondary}>
        {name}
      </TextCaption>
      <SpacerV rem={0.625} />
      {badge}
    </CardItem>
  )
}

export default AssetListItem
